
<template>
  <div class="h-screen w-full">
    <vx-card class="w-full">
      <div slot="no-body" class="full-page-bg-color">
        <iframe
          width="100%"
          allow="camera *;microphone *"
          height="600"
          :src="meeting_link"
          v-if="meeting_link"
          allowfullscreen
        ></iframe>
        <div v-else>
          <img
            src="logo.png"
            :alt="`${$t('basic.Logo')}`"
            class="block m-auto mb-5"
          />
          <h3 class="text-center text-color-base">{{ siteTitle }}</h3>
        </div>
      </div>
    </vx-card>
  </div>
</template>
<script>

// Validator
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const Repository = {
  onlinClass: RepositoryFactory.get(
    'TeachersModule',
    'OnlineClass'
  ),
  Braincert : RepositoryFactory.get(
    'AdmissionModule',
    'braincert'
  )}
export default {


  data () {
    return {
      meeting_link: '',
      class_id:null,
      onlineClass:null
    }
  },

  created () {
    this.meeting_link = this.$route.params.lunchURL
    this.class_id = this.$route.params.class_id
    this.getLink()
  },
  methods: {
  // show
    getLink () {
      Repository.onlinClass.show(this.class_id).then(res => {
        this.onlineClass =  res.data
        const
          braincert_class_id = this.onlineClass.braincert_class_id,
          subject = this.onlineClass.subjectName,
          name = this.onlineClass['class-name']
        // alert(braincert_class_id)
        Repository.Braincert.getLunchURL(braincert_class_id, name, subject).then(res => {
          this.meeting_link =  res.launchurl
        }).catch(err => console.error('braincert classlaunch', err))
      }).catch(err => console.error('onlinClass Show', err))
    }

  }

}
</script>
